import React, { useState } from 'react';
import TodoList from './components/TodoList';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`app ${darkMode ? 'dark-mode' : 'light-mode'}`}>
        <div  className="mode-switch">
          <label htmlFor="mode-toggle">
            <input
              class="hidden"
              type="checkbox"
              id="mode-toggle"
              checked={darkMode}
              onChange={toggleDarkMode}
            />
            <i title="Toggle Light/Dark Mode" class='bx bx-bulb' ></i>
          </label>
        </div>
      <TodoList />
    </div>
  );
}

export default App;