import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Group from './Group';

const TodoList = () => {
  const [groups, setGroups] = useState(() => {
    const storedGroups = localStorage.getItem('groups');
    return storedGroups ? JSON.parse(storedGroups) : [];
  });

  useEffect(() => {
    localStorage.setItem('groups', JSON.stringify(groups));
  }, [groups]);

  const handleTaskToggle = (taskId) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) => ({
        ...group,
        tasks: group.tasks.map((task) =>
          task.id === taskId ? { ...task, completed: !task.completed } : task
        ),
      }))
    );
  };

  const handleSubTaskToggle = (taskId, subTaskId) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) => ({
        ...group,
        tasks: group.tasks.map((task) =>
          task.id === taskId
            ? {
                ...task,
                subTasks: task.subTasks.map((subTask) =>
                  subTask.id === subTaskId ? { ...subTask, completed: !subTask.completed } : subTask
                ),
              }
            : task
        ),
      }))
    );
  };

  const addGroup = () => {
  const newGroup = {
    id: Date.now(),
    name: 'New Section',
    description: '',
    tasks: [],
  };
  setGroups([newGroup, ...groups]);
};

  const editGroup = (groupId, updatedGroup) => {
    setGroups(groups.map((group) => (group.id === groupId ? { ...group, ...updatedGroup } : group)));
  };

  const deleteGroup = (groupId) => {
    if (window.confirm('Are you sure you want to delete this group?')) {
      setGroups(groups.filter((group) => group.id !== groupId));
    }
  };

  const addTask = (groupId) => {
  setGroups(
    groups.map((group) =>
      group.id === groupId
        ? {
            ...group,
            tasks: [
              {
                id: Date.now(),
                name: 'New Task',
                completed: false,
                comments: [],
                images: [],
                links: [],
                subTasks: [],
              },
              ...group.tasks,
            ],
          }
        : group
    )
  );
};

  const editTask = (groupId, taskId, updatedTask) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) => (task.id === taskId ? { ...task, ...updatedTask } : task)),
            }
          : group
      )
    );
  };

  const deleteTask = (groupId, taskId) => {
    if (window.confirm('Are you sure you want to delete this Task?')) {
      setGroups(
        groups.map((group) =>
          group.id === groupId ? { ...group, tasks: group.tasks.filter((task) => task.id !== taskId) } : group
        )
      );
    }
  };

  const addSubTask = (groupId, taskId) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) =>
                task.id === taskId
                  ? {
                      ...task,
                      subTasks: [
                        ...task.subTasks,
                        {
                          id: task.subTasks.length + 1,
                          name: 'New Sub-Task',
                          completed: false,
                        },
                      ],
                    }
                  : task
              ),
            }
          : group
      )
    );
  };

  const editSubTask = (groupId, taskId, subTaskId, updatedSubTask) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) =>
                task.id === taskId
                  ? {
                      ...task,
                      subTasks: task.subTasks.map((subTask) =>
                        subTask.id === subTaskId ? { ...subTask, ...updatedSubTask } : subTask
                      ),
                    }
                  : task
              ),
            }
          : group
      )
    );
  };

  const deleteSubTask = (groupId, taskId, subTaskId) => {
    if (window.confirm('Are you sure you want to delete this sub-task?')) {
      setGroups(
        groups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                tasks: group.tasks.map((task) =>
                  task.id === taskId
                    ? {
                        ...task,
                        subTasks: task.subTasks.filter((subTask) => subTask.id !== subTaskId),
                      }
                    : task
                ),
              }
            : group
        )
      );
    }
  };

  const addComment = (groupId, taskId, comment) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) =>
                task.id === taskId ? { ...task, comments: [...task.comments, comment] } : task
              ),
            }
          : group
      )
    );
  };

  const removeComment = (groupId, taskId, commentIndex) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) =>
                task.id === taskId
                  ? {
                      ...task,
                      comments: task.comments.filter((_, index) => index !== commentIndex),
                    }
                  : task
              ),
            }
          : group
      )
    );
  };

  const addImage = (groupId, taskId, image) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) =>
                task.id === taskId ? { ...task, images: [...task.images, image] } : task
              ),
            }
          : group
      )
    );
  };

  const removeImage = (groupId, taskId, imageIndex) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) =>
                task.id === taskId
                  ? {
                      ...task,
                      images: task.images.filter((_, index) => index !== imageIndex),
                    }
                  : task
              ),
            }
          : group
      )
    );
  };

  const addLink = (groupId, taskId, link) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) =>
                task.id === taskId ? { ...task, links: [...task.links, link] } : task
              ),
            }
          : group
      )
    );
  };

  const removeLink = (groupId, taskId, linkIndex) => {
    setGroups(
      groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              tasks: group.tasks.map((task) =>
                task.id === taskId
                  ? {
                      ...task,
                      links: task.links.filter((_, index) => index !== linkIndex),
                    }
                  : task
              ),
            }
          : group
      )
    );
  };

  const onDragEnd = (result) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    if (type === 'group') {
      const newGroups = Array.from(groups);
      const [reorderedGroup] = newGroups.splice(source.index, 1);
      newGroups.splice(destination.index, 0, reorderedGroup);

      setGroups(newGroups);
    } else if (type === 'task') {
      const sourceGroupId = parseInt(source.droppableId);
      const destinationGroupId = parseInt(destination.droppableId);

      const sourceGroup = groups.find((group) => group.id === sourceGroupId);
      const destinationGroup = groups.find((group) => group.id === destinationGroupId);

      const newSourceTasks = Array.from(sourceGroup.tasks);
      const [reorderedTask] = newSourceTasks.splice(source.index, 1);

      if (sourceGroupId === destinationGroupId) {
        newSourceTasks.splice(destination.index, 0, reorderedTask);
        const newGroups = groups.map((group) =>
          group.id === sourceGroupId ? { ...group, tasks: newSourceTasks } : group
        );
        setGroups(newGroups);
      } else {
        const newDestinationTasks = Array.from(destinationGroup.tasks);
        newDestinationTasks.splice(destination.index, 0, reorderedTask);

        const newGroups = groups.map((group) => {
          if (group.id === sourceGroupId) {
            return { ...group, tasks: newSourceTasks };
          } else if (group.id === destinationGroupId) {
            return { ...group, tasks: newDestinationTasks };
          }
          return group;
        });

        setGroups(newGroups);
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <header>
          <h1>Notes & Tasks</h1>
          <div>
          <button className="btn btnAddGroup" onClick={addGroup}>
            <i className="bx bx-plus-circle"></i>
            <span>
              <b>Add New Section</b>
            </span>
          </button>
        </div>
        </header>
        <Droppable droppableId="groups" type="group">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {groups.map((group, index) => (
                <Draggable key={group.id} draggableId={group.id.toString()} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <Group
                        key={group.id}
                        group={group}
                        handleTaskToggle={handleTaskToggle}
                        handleSubTaskToggle={handleSubTaskToggle}
                        addTask={addTask}
                        editTask={editTask}
                        deleteTask={deleteTask}
                        addSubTask={addSubTask}
                        editSubTask={editSubTask}
                        deleteSubTask={deleteSubTask}
                        addComment={addComment}
                        removeComment={removeComment}
                        addImage={addImage}
                        removeImage={removeImage}
                        addLink={addLink}
                        removeLink={removeLink}
                        editGroup={editGroup}
                        deleteGroup={deleteGroup}
                        dragHandle={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        
      </div>
    </DragDropContext>
  );
};

export default TodoList;