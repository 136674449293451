import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Task from './Task';

const Group = ({
    group,
    handleTaskToggle,
    handleSubTaskToggle,
    addTask,
    editTask,
    deleteTask,
    addSubTask,
    editSubTask,
    deleteSubTask,
    addComment,
    removeComment,
    addImage,
    removeImage,
    addLink,
    removeLink,
    editGroup,
    deleteGroup,
    dragHandle,
}) => {
    const [editingGroup, setEditingGroup] = useState(false);
    const [updatedGroup, setUpdatedGroup] = useState({
        name: group.name,
        description: group.description,
    });

    const handleGroupEdit = () => {
        setEditingGroup(true);
    };

    const handleGroupSave = () => {
        editGroup(group.id, updatedGroup);
        setEditingGroup(false);
    };

    const handleGroupCancel = () => {
        setEditingGroup(false);
        setUpdatedGroup({
            name: group.name,
            description: group.description,
        });
    };

    return (
        <div className="group" {...dragHandle}>
      {editingGroup ? (
        <div>
          <input
            placeholder="Group Label"
            type="text"
            value={updatedGroup.name}
            onChange={(e) => setUpdatedGroup({ ...updatedGroup, name: e.target.value })}
          />
          <textarea
            placeholder="Notes"
            value={updatedGroup.description}
            onChange={(e) => setUpdatedGroup({ ...updatedGroup, description: e.target.value })}
          />
          <button onClick={handleGroupSave}>Save</button>
          <button onClick={handleGroupCancel}>Cancel</button>
        </div>
      ) : (
        <div>
          <div className="group-details">
            <span {...dragHandle}>
                <i class='bx bx-menu'></i>
              </span>
            <h3>
              {group.name}{' '}
              <div>
              <button title="Add New Task" className="btnAddTask" onClick={() => addTask(group.id)}>
<i className="bx bx-plus-circle"></i>
<span>
<b>Add Task</b>
</span>
</button>
              <button onClick={handleGroupEdit}>
                <i className="bx bx-edit-alt"></i>
                <span>Edit Section</span>
              </button>
              <button onClick={() => deleteGroup(group.id)}>
                <i className="bx bx-trash"></i>
                <span>Delete Section</span>
              </button>
            </div>
          </h3>
          <p>{group.description}</p>
          </div>
          <Droppable droppableId={group.id.toString()} type="task">
            {(provided) => (
              <ul className="task-list" {...provided.droppableProps} ref={provided.innerRef}>
                {group.tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={`task-${task.id}`} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <Task
                          key={task.id}
                          group={group}
                          task={task}
                          handleTaskToggle={handleTaskToggle}
                          handleSubTaskToggle={handleSubTaskToggle}
                          addSubTask={addSubTask}
                          editTask={editTask}
                          deleteTask={deleteTask}
                          editSubTask={editSubTask}
                          deleteSubTask={deleteSubTask}
                          addComment={addComment}
                          removeComment={removeComment}
                          addImage={addImage}
                          removeImage={removeImage}
                          addLink={addLink}
                          removeLink={removeLink}
dragHandle={provided.dragHandleProps}
/>
</div>
)}
</Draggable>
))}
{provided.placeholder}
</ul>
)}
</Droppable>

</div>
)}
</div>
    );
};
export default Group;