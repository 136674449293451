import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const Task = ({
  group,
  task,
  handleTaskToggle,
  handleSubTaskToggle,
  addSubTask,
  editTask,
  deleteTask,
  editSubTask,
  deleteSubTask,
  addComment,
  removeComment,
  addImage,
  removeImage,
  addLink,
  removeLink,
  dragHandle,
}) => {
  const [editingTask, setEditingTask] = useState(false);
  const [updatedTask, setUpdatedTask] = useState({
    name: task.name,
  });

  const [isNotesActive, setIsNotesActive] = useState(false);
  const [editingSubTaskId, setEditingSubTaskId] = useState(null);
  const [editingSubTaskName, setEditingSubTaskName] = useState('');

  const handleTaskEdit = () => {
    setEditingTask(true);
  };

  const handleTaskSave = () => {
    editTask(group.id, task.id, updatedTask);
    setEditingTask(false);
  };

  const handleTaskCancel = () => {
    setEditingTask(false);
    setUpdatedTask({
      name: task.name,
    });
  };

  const toggleNotesActive = () => {
    setIsNotesActive(!isNotesActive);
  };

  return (
    <li className="task" {...dragHandle}>
      {editingTask ? (
        <div className="task-actions">
          <input
            type="text"
            value={updatedTask.name}
            onChange={(e) => setUpdatedTask({ ...updatedTask, name: e.target.value })}
          />
          <button onClick={handleTaskSave}>Save</button>
          <button onClick={handleTaskCancel}>Cancel</button>
        </div>
      ) : (
        <div className="task-display">
          <div className="task-all">
            <br className="noshow" />
            <div className="task-wrap">
              <div className="task-top">
                <div className="task-actions">
                  <span {...dragHandle}>
                    <i class='bx bx-menu'></i>
                  </span>
                  <button title="Edit Task" onClick={handleTaskEdit}>
                    <i className="bx bx-edit-alt"></i> <span className="hidden">Edit Task</span>
                  </button>
                  <button title="Delete Task & Sub-Tasks" onClick={() => deleteTask(group.id, task.id)}>
                    <i className="bx bx-trash"></i> <span className="hidden">Delete Task</span>
                  </button>
                </div>
                <div className="task-details">
                  <div>
                    <input type="checkbox" checked={task.completed} onChange={() => handleTaskToggle(task.id)} />
                    <span class="noshow">#</span> {task.name}{''} 
                  </div>
                </div>
              </div>
              <div className="text-nowrap">
                <button title="Add Sub-Tasks" className="btn btnAddSubTask" onClick={() => addSubTask(group.id, task.id)}>
                  <i className="bx bx-plus-circle"></i>
                  <span>
                    <b>Add Sub-Task</b>
                  </span>
                </button>
                <button title="Toggle Notes" className="btn btnToggleNotes" onClick={toggleNotesActive}>
                  <i className="bx bx-dots-horizontal-rounded"></i>
                </button>
              </div>
            </div>

            <Droppable droppableId={`task-${task.id}-subtasks`} type="subtask">
              {(provided) => (
                <ul className="sub-tasks" {...provided.droppableProps} ref={provided.innerRef}>
                  {task.subTasks.map((subTask, index) => (
                    <Draggable key={subTask.id} draggableId={`subtask-${subTask.id}`} index={index}>
                      {(provided) => (
                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <div>
                            {editingSubTaskId === subTask.id ? (
                              <div className="edit-task">
                                <input
                                  type="text"
                                  value={editingSubTaskName}
                                  onChange={(e) => setEditingSubTaskName(e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      editSubTask(group.id, task.id, subTask.id, {
                                        name: editingSubTaskName,
                                      });
                                      setEditingSubTaskId(null);
                                      setEditingSubTaskName('');
                                    }
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="task-actions">
                                <span {...provided.dragHandleProps}>
                                  <i class='bx bx-menu'></i>
                                </span>
                                <button
                                  title="Edit Sub-Task"
                                  onClick={() => {
                                    setEditingSubTaskId(subTask.id);
                                    setEditingSubTaskName(subTask.name);
                                  }}
                                >
                                  <i className="bx bx-edit-alt"></i> <span className="hidden">Edit Sub-Task</span>
                                </button>
                                <button
                                  title="Delete Sub-Task"
                                  onClick={() => deleteSubTask(group.id, task.id, subTask.id)}
                                >
                                  <i className="bx bx-trash"></i> <span className="hidden">Delete Sub-Task</span>
                                </button>
                              </div>
                            )}
                          </div>
                          <div>
                            <i className="child-icon bx bx-subdirectory-right"> <span class='noshow'>&nbsp;-&nbsp;</span> </i>
                            <input
                              type="checkbox"
                              checked={subTask.completed}
                              onChange={() => handleSubTaskToggle(task.id, subTask.id)}
                            />
                             {editingSubTaskId === subTask.id ? <span>{editingSubTaskName}</span> : <span>{subTask.name}</span>}
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </div>
          <div className={`notes ${isNotesActive ? 'active' : ''}`}>
            <div className="note-item">
              <div className="note-heading">
                <h4 className="hidden">Comments</h4>
                <input
                  type="text"
                  placeholder="Add Task Note"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addComment(group.id, task.id, e.target.value);
                      e.target.value = '';
                    }
                  }}
                />
              </div>
              <ul className="note-list comment-list">
                {task.comments.map((comment, index) => (
                  <li key={index}>
                    <button title="Remove Note" onClick={() => removeComment(group.id, task.id, index)}>
                      <i className="bx bx-trash"></i> <span className="hidden">Remove Note</span>
                    </button>
                    {comment}{' '}
                  </li>
                ))}
              </ul>
            </div>
            <div className="note-item">
              <div className="note-heading">
                <h4 className="hidden">Links</h4>
                <input
                  type="text"
                  placeholder="Add Link"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addLink(group.id, task.id, e.target.value);
                      e.target.value = '';
                    }
                  }}
                />
              </div>
              <ul className="note-list link-list">
                {task.links.map((link, index) => (
                  <li key={index}>
                    <button title="Remove Link" onClick={() => removeLink(group.id, task.id, index)}>
                      <i className="bx bx-trash"></i> <span className="hidden">Remove Link</span>
                    </button>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="note-item">
              <div className="note-heading">
                <h4 className="hidden">Images</h4>
                <input
                  type="text"
                  placeholder="Enter Image URLs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addImage(group.id, task.id, e.target.value);
                      e.target.value = '';
                    }
                  }}
                />
              </div>
              <ul className="note-list image-list">
                {task.images.map((image, index) => (
                  <li key={index}>
                    <img src={image} alt={`Task ${task.id} Image ${index}`} />
                    <button title="Remove Image" onClick={() => removeImage(group.id, task.id, index)}>
                      <i className="bx bx-trash"></i> <span className="hidden">Remove Image</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default Task;